/*
 * Licensed to the Apache Software Foundation (ASF) under one or more
 * contributor license agreements.  See the NOTICE file distributed with
 * this work for additional information regarding copyright ownership.
 * The ASF licenses this file to You under the Apache License, Version 2.0
 * (the "License"); you may not use this file except in compliance with
 * the License.  You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
.karavan .properties .headers {
    grid-row-gap: 10px;
    row-gap: 10px;
    display: contents;
}

.karavan .properties .headers .top {
    width: 100%;
    display: flex;
    flex-direction: row;
}

.karavan .properties .headers .top h1 {
    width: 100%;
    margin-top: auto;
    margin-bottom: auto;
}

.karavan .properties .footer {
    height: 100%;
    display: contents;
}

.karavan .properties .pf-v5-c-form {
    row-gap: 10px;
}

.karavan .properties .pf-v5-c-form__group-label {
    padding-bottom: 3px;
    display: flex;
    justify-content: space-between;
}

.karavan .properties .pf-v5-c-form-control:focus-within {
    --pf-v5-c-form-control--after--BorderBottomColor: var(--pf-v5-c-form-control--after--BorderBottomColor);
    --pf-v5-c-form-control--after--BorderBottomWidth: var(--pf-v5-c-form-control--after--BorderBottomWidth);
}

.karavan .properties .pf-v5-c-select {
    --pf-v5-c-select__toggle--FontSize: 14px;
    --pf-v5-c-select__menu-item--FontSize: 14px;
}

.karavan .properties .input-group .pf-v5-c-text-input-group__text {
    width: 100%;
}

.karavan .properties .input-group .pf-v5-c-chip-group,
.karavan .properties .input-group .pf-v5-c-text-input-group__main,
.karavan .properties .input-group .pf-v5-c-chip-group .pf-v5-c-chip-group__list,
.karavan .properties .input-group .pf-v5-c-chip-group .pf-v5-c-chip-group__list .pf-v5-c-chip-group__list-item,
.karavan .properties .input-group .pf-v5-c-chip-group .pf-v5-c-chip-group__main {
    display: block;
}

.karavan .properties .input-group .pf-v5-c-chip-group {
    margin-left: 0;
}

.karavan .properties .input-group .pf-v5-c-chip-group .pf-v5-c-chip .pf-v5-c-chip__text {
    max-width: inherit;
}

.karavan .properties .input-group .pf-v5-c-chip-group .pf-v5-c-chip {
    width: 100%;
}

.karavan .properties .input-group .pf-v5-c-text-input-group__utilities {
    align-items: flex-end;
    margin-top: auto;
}

.karavan .properties .chip .pf-v5-c-button {
    position: absolute;
    right: 0;
}

.karavan .properties .expression-title {
    font-size: 17px;
    font-weight: bold;
}

.karavan .properties .text-area {
    font-size: 13px;
}

.karavan .properties .pf-v5-c-select__menu-search {
    padding: 0px 6px 6px 6px;
}

.karavan .properties .pf-v5-c-select__toggle-typeahead {
    height: auto;
}

.karavan .properties .pf-v5-c-select__menu-item {
    /*width: 280px;*/
}

.karavan .properties .pf-v5-c-select__menu-item-description {
    overflow-wrap: anywhere;
}

.karavan .properties .number {
    display: flex;
    justify-content: space-between;
}

.karavan .properties .number .number-property {
    width: 100%;
}

.karavan .properties .number .clear-button {
    color: #b1b1b7;
    --pf-v5-c-button--BorderRadius: var(--pf-v5-c-button--m-control--BorderRadius);
    --pf-v5-c-button--disabled--BackgroundColor: var(--pf-v5-c-button--m-control--disabled--BackgroundColor);
    --pf-v5-c-button--after--BorderWidth: var(--pf-v5-c-button--m-control--after--BorderWidth);
    --pf-v5-c-button--after--BorderColor: var(--pf-v5-c-button--m-control--after--BorderTopColor) var(--pf-v5-c-button--m-control--after--BorderRightColor) var(--pf-v5-c-button--m-control--after--BorderBottomColor) var(--pf-v5-c-button--m-control--after--BorderLeftColor);
    color: var(--pf-v5-c-button--m-control--Color);
    background-color: var(--pf-v5-c-button--m-control--BackgroundColor);
    padding-left: 5px;
    padding-right: 5px;
}

.karavan .properties .help-button {
    font-size: 12px;
}

.karavan .properties .component-selector {
    border-width: var(--pf-v5-global--BorderWidth--sm);
    border-top-color: var(--pf-v5-global--BorderColor--300);
    border-right-color: var(--pf-v5-global--BorderColor--300);
    border-bottom-color: var(--pf-v5-global--BorderColor--200);
    border-left-color: var(--pf-v5-global--BorderColor--300);
    border-style: solid;
}

.karavan .properties .object-value {
    display: flex;
    flex-direction: row;
    margin-bottom: 3px;
}

.karavan .properties .object-key-value .object {
    padding-top: 0;
}

.karavan .properties .object-value .delete-button {
    margin: 0;
    padding: 5px 3px 0px 6px;
    height: 16px;
    color: #909090;
}

.karavan .properties .object-key-value,
.karavan .properties .object-key-value .object-field {
    display: flex;
    flex-direction: row;
    gap: 3px;
}

.karavan .properties .object-key-value .delete-button {
    margin: auto 0 22px 0;
    padding: 0px 0px 0px 3px;
    height: 16px;
    color: #909090;
}

.karavan .properties .expression,
.karavan .properties .object,
.karavan .properties .dataformat,
.karavan .properties .parameters {
    padding-top: 6px;
    padding-left: 16px;
    row-gap: 6px;
    display: grid;
    width: 100%;
}

.karavan .properties .expression .pf-v5-c-form__group-label,
.karavan .properties .object .pf-v5-c-form__group-label,
.karavan .properties .dataformat .pf-v5-c-form__group-label,
.karavan .properties .parameters .pf-v5-c-form__group-label {
    font-weight: 100;
}

.karavan .properties .expression .pf-v5-c-form__group,
.karavan .properties .object .pf-v5-c-form__group,
.karavan .properties .dataformat .pf-v5-c-form__group,
.karavan .properties .parameters .pf-v5-c-form__group {
    margin-bottom: 10px;
}

.karavan .properties .expression .pf-v5-c-select__menu-wrapper,
.karavan .properties .object .pf-v5-c-select__menu-wrapper,
.karavan .properties .dataformat .pf-v5-c-select__menu-wrapper,
.karavan .properties .parameters .pf-v5-c-select__menu-wrapper {
    width: 350px;
}

.karavan .properties .change-button {
    font-size: 15px;
    height: 15px;
    line-height: 1;
    border: 0;
    padding: 0;
    margin: auto auto auto 6px;
    background: transparent;
}

.karavan .properties .change-button svg {
    margin-right: 6px;
}

.karavan .properties .add-button {
    color: var(--pf-v5-global--active-color--100);
}

.karavan .properties .delete-button {
    color: #909090;
}

.karavan .properties .pf-v5-c-expandable-section__toggle {
    margin: 0;
    padding: 0;
}

.karavan .properties .pf-v5-c-expandable-section__content {
    margin: 0;
}

.karavan .properties .pf-v5-c-expandable-section__content p {
    min-height: 6px;
}

.karavan .properties .header-menu-toggle {
    padding-left: 6px;
    padding-right: 0;
}

.karavan .properties .component-headers {
    margin-left: 24px;
}

.karavan .properties .component-headers .pf-v5-c-clipboard-copy.pf-m-inline {
    background-color: transparent;
}
