.trace-modal {
    height: 90%;
}

.trace-no-padding {
    padding: 0;
}

.trace-padding-bottom-only {
    padding: 0 0 var(--pf-v5-c-panel__header--PaddingBottom) 0;
}

.trace-modal .pf-v5-c-modal-box__body {
    display: flex;
}

.trace-modal .container {
    display: flex;
    flex-direction: row;
    padding: 0;
    flex: 1;
}

.trace-modal .panel1 {
    flex: 1;
    padding: 10px;
}

.trace-modal .panel1 .scrollable,
.trace-modal .panel2 .scrollable {
    overflow-y: auto;
    height: 95%;
    padding-top: 10px;
}

.trace-modal .panel2 {
    flex: 4;
    padding: 10px;
}

/*.trace-modal .panel2 .pf-v5-c-tab-content {*/
/*    overflow-y: auto;*/
/*    height: 100%;*/
/*}*/