/*
 * Licensed to the Apache Software Foundation (ASF) under one or more
 * contributor license agreements.  See the NOTICE file distributed with
 * this work for additional information regarding copyright ownership.
 * The ASF licenses this file to You under the Apache License, Version 2.0
 * (the "License"); you may not use this file except in compliance with
 * the License.  You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

.karavan .refresher {
    width: 34px;
    height: 34px;
}

.karavan .refresher .spinner {
    position: absolute;
    width: 34px;
    height: 34px;
}

.karavan .refresher .button {
    position: absolute;
    width: 34px;
    height: 34px;
    padding: 0;
    font-size: 16px;
}

.karavan .refresher .button .pf-v5-c-button__icon {
    margin: 0;
}