.karavan .projects-page .tools-section .tools {
    padding-top: 5px;
}

.karavan .project-page .tools-section .tools {
    padding-top: 3px;
}

.license-warning-banner {
    color: white !important;
    background-color: #c9190b !important;
}

.license-warning-banner-text {
    color: white !important;
    font-size: large !important;
}
