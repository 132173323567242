.custom-breadcrumb .pf-v5-c-breadcrumb__list {
 padding: 0px;
 margin: 0px;
}
.custom-breadcrumb .pf-v5-c-breadcrumb__item-divider {
    margin-left: 5px;
}

.custom-breadcrumb .pf-v5-c-breadcrumb__item {
   font-size: 15px;
}