/*
 * Licensed to the Apache Software Foundation (ASF) under one or more
 * contributor license agreements.  See the NOTICE file distributed with
 * this work for additional information regarding copyright ownership.
 * The ASF licenses this file to You under the Apache License, Version 2.0
 * (the "License"); you may not use this file except in compliance with
 * the License.  You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

.karavan .kamelet-designer {
    display: block;
    height: 100vh;
    width: 100%;
    overflow-y: auto;
    padding-bottom: 106px;
}

.karavan .kamelet-designer .pf-v5-c-drawer__content,
.karavan .kamelet-designer .main {
    background-color: var(--pf-v5-global--BackgroundColor--light-300);
}
.karavan .kamelet-designer .icon {
    height: 20px;
    width: 20px;
    border: none;
    -webkit-user-select: none;
    -o-user-select: none;
    user-select: none;
}

.karavan .kamelet-designer .properties {
    padding: 10px 10px 10px 10px;
    background: transparent;
    width: 100%;
    height: 100%;
    overflow: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}