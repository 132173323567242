.logo-container {
    background-color: #ffffff;
    height: 65px;
    width: 96px;
}

.karavan .nav-buttons .pf-v5-c-button {
    width: 96px;
    height: 96px;
}

.karavan .nav-buttons .logo {
    margin-top: 0;
    margin-bottom: 0;
    margin-left: 4px;
    width: 90px;
    height: 96px;
}

.karavan .nav-buttons .pf-v5-c-button svg {
    width: 1.6em;
    height: 1.6em;
}