.create-mdm-data__dpc-container .pf-v5-c-drawer__body {
  border-bottom: 1px solid;
  margin-bottom: 20px;
  height: 30px;
}

.create-mdm-data__dpc-header {
  font-size: x-large;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #D3D3D3;
  padding: 8px;
  margin-bottom: 10px;
}

.create-mdm-data__dpc-grid {
  height: 90vh;
  width: 100%;
}

.create-mdm-data__input-contianer {
  width: 100%;
  margin-right: 3em;
}

.rectangle-input::after {
  border: none;
}
.rectangle-input::before {
  border: none;
}

.create-panel-content .pf-v5-c-drawer__body {
  border-bottom: 1px solid;
  margin-bottom: 20px;
}

.create-header {
  font-size: x-large;
}

.pf-m-readonly {
  background-color: white;
}
.pf-m-readonly input {
  background-color: #dcdcdc !important;
}

.pf-m-readonly input {
  background-color: #dcdcdc !important;
}

.custom-datepicker .pf-v5-c-input-group__item {
  min-width: 90%;
  max-width: 90%;
  height: 3.1em;
}
.custom-datepicker .pf-v5-c-form-control {
  min-width: 100%;
  border: 2px solid #d3d3d3 !important;
  padding: 0.5rem !important;
  width: 93% !important;
  border-radius: 6px !important;
}
.custom-datepicker input::after {
  height: 80px !important;
  border: 2px solid #d3d3d3 !important;
  border-radius: 6px !important;
  padding: 0.5rem !important;
  width: 92% !important;
}

.custom-datepicker .pf-v5-c-form-control > :is(input, select, textarea) {
  padding-block-start: 0px;
}

.mdm-validation {
  color: red;
}


.pf-m-prev-month {
  display: none;
}
.pf-m-next-month {
  display: none;
}

.pf-v5-c-calendar-month__header {
  width: 85%;
}

.pf-v5-c-calendar-month__header-year input::before{
  /* height: 20px; */
  width: 65px;
}

.pf-v5-c-calendar-month__header-year {
  width: 37%;
}


/* Hide spinners in WebKit browsers (Chrome, Safari) */
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
-webkit-appearance: none;
margin: 0;
}

/* Hide spinners in Firefox */
input[type="number"] {
-moz-appearance: textfield;
}

.create-mdm-data__dpc-required-fields::after {
content: ' *';
color: #FF0000; 
}

.create-mdm-data__input-contianer .custom-datepicker {
min-width: 92%!important;
}