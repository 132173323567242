
.custom-input-field div {
  max-height: 35px;
  background-color: white;
}
.filter-custom-active-button {
  border: 2px solid #0066CC !important;
  color: #0066CC;
  font-weight: bold;
}

.filter-table-cols {
  width: 27%;
  padding: 7px !important;
  border-bottom: 0px solid white;
}

 .filters_footer {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  padding: 10px;
}

 .filters__action-buttons .filters__button {
  background-color: white;
  align-items: center;

  font-size: bold;
  border-radius: 8px;
  margin-left: 10px;
  max-height: 35px;
  height: 37px;
  min-width: 20%;
  border: 2px solid white;
}

.filters__action-buttons {
  height: 37px;
}

.filter-container  {
  box-shadow: none !important;
}

.custom-dropdown {
  max-height: 300px;
  overflow-y: auto; /* Enable scrolling */
}


.filters-select-typehaed-infinite-scrollbar {
  background-color: white !important;
  max-height:300px;
  overflow-y: auto;
}

.filter-table-cols div {
  background-color: white;
}

.filter-table-infinte-td {
  width: 23vw;
}
.filter-table-infinte-td button::before{
  border-bottom: none !important;
}

.filter-table-infinte-td button::after{
  border-bottom: none !important;
}

.filter-table-cols .pf-c-form-control {
  border-bottom: none !important;
}

.filter-table-cols .pf-m-typeahead::after {
  border-bottom: none !important;
}
.filter-table-cols .pf-m-typeahead {
  border: 1px solid #D3D3D3 !important;
}

.filter-table-infinte-td >button {
  border: 1px solid #D3D3D3 !important;
}

.pf-v5-c-menu__content:has(.search-input-infinte) {
  max-height: 48vh !important;
}

