/*
 * Licensed to the Apache Software Foundation (ASF) under one or more
 * contributor license agreements.  See the NOTICE file distributed with
 * this work for additional information regarding copyright ownership.
 * The ASF licenses this file to You under the Apache License, Version 2.0
 * (the "License"); you may not use this file except in compliance with
 * the License.  You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
.expression-modal {
    height: 80%;
}

.container {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: stretch;
}

.panel-top {
    flex: 1;
    height: 100%;
    border: 1px solid lightgray;
}

.panel-middle {
    /*height: 64px;*/
}
@keyframes smooth-appear {
    to {
        bottom: 20px;
        opacity:1;
    }
}

.panel-bottom {
    flex: 1;
    height: 100%;
    overflow-y: auto;
    opacity:0;
    animation: smooth-appear 1s ease forwards;
}

.context {
    width: 100%;
    display: flex;
    justify-content: space-between;
    gap: 64px;
}

.context-column {
    flex: 1;
}

.context-column-right {
    margin-left: 12px;
}

.context-column .pf-v5-c-clipboard-copy.pf-m-inline {
    display: flex;
    width: fit-content;
}

.context-column .pf-v5-c-clipboard-copy__text{
    white-space: nowrap;
}