/*
 * Licensed to the Apache Software Foundation (ASF) under one or more
 * contributor license agreements.  See the NOTICE file distributed with
 * this work for additional information regarding copyright ownership.
 * The ASF licenses this file to You under the Apache License, Version 2.0
 * (the "License"); you may not use this file except in compliance with
 * the License.  You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

.karavan .properties .variables-toggle {
    padding-left: 6px;
    padding-right: 6px;
}

.karavan .properties .variables-toggle .pf-v5-c-button__icon.pf-m-start {
    margin-inline-end: 0;
}

.karavan .properties .variables-toggle .pf-v5-c-menu-toggle__controls {
    display: none;
}

.pf-v5-c-popover .variables-toggle-form {
    width: 300px;
}

.pf-v5-c-popover .variables-toggle-form .pf-v5-c-form__group {
    grid-template-columns: 1fr 2fr;
}