/*
 * Licensed to the Apache Software Foundation (ASF) under one or more
 * contributor license agreements.  See the NOTICE file distributed with
 * this work for additional information regarding copyright ownership.
 * The ASF licenses this file to You under the Apache License, Version 2.0
 * (the "License"); you may not use this file except in compliance with
 * the License.  You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

.karavan .rest-designer .rest-card,
.karavan .rest-designer .rest-config-card,
.karavan .rest-designer .method-card {
    border-style: dashed;
    border-radius: 42px;
    border-width: 1px;
}

.karavan .rest-designer .rest-card {
    padding: 16px 16px 6px 16px;
    margin-right: 80px;
    margin-left: 80px;
}

.karavan .rest-designer .rest-config-card {
    padding: 16px 16px 34px 16px;
    margin-right: 80px;
    margin-left: 80px;
}

.karavan .rest-designer .method-card {
    padding: 0 0 0 16px;
    margin-bottom: 6px;
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 16px;
    height: 44px;
    cursor: pointer;
}

.karavan .rest-card-unselected {
    border-color: var(--pf-v5-global--Color--200);
    background-color: transparent;
}

.karavan .rest-card-selected {
    border-color: var(--pf-v5-global--primary-color--100);
}

.karavan .rest-card .title {
    margin: auto 0 auto 0;
    font-weight: bold;
    white-space: nowrap;
}

.karavan .rest-card .description {
    margin: auto 0 auto 0;
    min-width: 200px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.karavan .rest-card .delete-button {
    position: absolute;
    top: -7px;
    right: -7px;
    line-height: 1;
    border: 0;
    padding: 0;
    margin: 0;
    background: transparent;
    color: #909090;
    visibility: hidden;
    z-index: 100;
}

.karavan .rest-card:hover .delete-button {
    visibility: visible;
}

.karavan .rest-designer {
    display: block;
    height: 100vh;
    width: 100%;
    overflow-y: auto;
}

.karavan .rest-designer .gallery {
    padding-top: 16px;
    padding-bottom: 116px;
}

.karavan .rest-designer .properties {
    padding: 10px 10px 10px 10px;
    background: transparent;
    width: 100%;
    height: 100%;
    overflow: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 106px;
}
/*rests*/
.karavan .rest-designer .properties .rest-properties .pf-v5-c-form__group-control {
    display: flex;
    flex-direction: column;
    gap: 6px;
}

.karavan .rest-designer .properties .rest-property {
    display: flex;
    flex-direction: row;
    gap: 3px;
}

.karavan .rest-designer .properties .rest-property .delete-button {
    padding: 3px;
    color: #b1b1b7;
}

.karavan .rest-designer .rest-designer-columns {
    display: block;
    height: 100%;
    background: #fafafa;
}

.karavan .rest-designer .graph {
    display: block;
    flex-direction: column;
    height: 100%;
    width: 100%;
    position: relative;
    overflow-y: auto;
}

.karavan .rest-designer .flows {
    /*width: 800px;*/
    margin: 0 auto 80px auto;
}

.karavan .rest-designer .flows .add-flow {
    margin-top: 16px;
    display: flex;
    justify-content: center;
}

.karavan .rest-designer .method-card .rest-method-desc {
    display: flex;
    flex-direction: column;
    flex: auto;
}

.karavan .rest-designer .rest-card-unselected,
.karavan .rest-designer .rest-config-card-unselected,
.karavan .rest-designer .method-card-unselected {
    border-color: var(--pf-v5-global--Color--200);
    background-color: transparent;
}

.karavan .rest-designer .rest-card-selected,
.karavan .rest-designer .rest-config-card-selected,
.karavan .rest-designer .method-card-selected {
    border-color: var(--pf-v5-global--primary-color--100);
}

.karavan .rest-designer .rest-card-selected .title {
    color: var(--pf-v5-global--primary-color--100);
}

.karavan .rest-designer .rest-card {
    display: flex;
    flex-direction: column;
    margin-top: 16px;
}

.karavan .rest-designer .header {
    display: flex;
    flex-direction: row;
    gap: 16px;
    height: 44px;
    margin-left: 6px;
    cursor: pointer;
    justify-content: space-between;
    position: relative;
}

.karavan .rest-designer .rest-config-card {
    position: relative;
    display: flex;
    flex-direction: row;
    gap: 16px;
    height: 44px;
    cursor: pointer;
}

.karavan .rest-designer .method-card .method {
    margin: auto 0 auto 0;
    border-radius: 3px;
    color: #fff;
    font-family: sans-serif;
    font-weight: 700;
    min-width: 80px;
    padding: 6px 0;
    text-align: center;
    text-shadow: 0 1px 0 rgb(0 0 0 / 10%);
}

.karavan .rest-designer .method-card-unselected .method {
    background: var(--pf-v5-global--Color--400);
}

.karavan .rest-designer .method-card-selected .method {
    background: var(--pf-v5-global--primary-color--100);
}

.karavan .rest-designer .rest-card .title,
.karavan .rest-designer .rest-config-card .title,
.karavan .rest-designer .method-card .title {
    margin: auto 0 auto 0;
    font-weight: bold;
    white-space: nowrap;
}

.karavan .rest-designer .rest-card .description,
.karavan .rest-designer .rest-config-card .description,
.karavan .rest-designer .method-card .description {
    margin: auto 0 auto 0;
    min-width: 200px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.karavan .rest-designer .rest-config-card .delete-button {
    position: absolute;
    top: 1px;
    right: 1px;
    line-height: 1;
    border: 0;
    padding: 0;
    margin: 0;
    background: transparent;
    color: #909090;
    visibility: hidden;
    z-index: 100;
}

.karavan .rest-designer .method-card .delete-button {
    position: relative;
    top: unset;
    right: 6px;
    line-height: unset;
    border: 0;
    padding: 0;
    margin: 0;
    background: transparent;
    color: #909090;
    visibility: hidden;
    z-index: 100;
}

.karavan .rest-designer .rest-config-card:hover .delete-button,
.karavan .rest-designer .rest-card:hover .delete-button,
.karavan .rest-designer .method-card:hover .delete-button {
    visibility: visible;
}

.karavan .rest-designer .rest-card .add-button {
    font-size: 15px;
    border: 0;
    background: transparent;
    color: var(--pf-v5-global--primary-color--100);
    z-index: 100;
}

.karavan .rest-designer .add-rest {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 16px;
    gap: 6px;
}
