.selectAll label {
    font-weight: bold;
}

.pf-m-flex-2:has(.drower-expanded) {
    width: 50% !important;
}

.mdm-list-more-10 {
    height: 89vh;
    overflow-y: scroll;
    overflow-x: hidden;
}

.active-filters-button {
    background-color: #F4F9FF !important;
} 


.logical-filters {
    padding-left: 5px;
    padding-right: 5px;
    font-weight: bold;
    text-transform: uppercase;
    color: black;
}

.operator-filters {
    padding-left: 5px;
    padding-right: 5px;
}