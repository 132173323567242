.change-section {
    padding: 10px;
    background: #f0f0f0;
    margin-top: 10px;
    font-size: 16px;
    font-weight: bold;
    display: flex;
    column-gap: 10px;
}

.change-section span:first-child{
    display: flex;
    flex-direction: row;
    flex-direction: column;
    font-size: 12px;
}

.change-section span:first-child svg:first-child{
    margin-bottom: -2px;
    margin-top: 3px;
}

.change-section span:nth-child(2) {
    color: #F9A825;
}

.change-section span:nth-child(3) {
    color: #63993D;
}
.change-section span:nth-child(4) {
    color: #F0561D;
}
.pf-v5-c-accordion__toggle:hover .pf-v5-c-accordion__toggle-text{
    color: #000;
}

.commit-icon{
    width: 100px;
}

.commit-icon span{
    display: inline-block;
    height: 15px;
}

.diff-detail-stats{
    display: flex;
    gap: 11px;
    color: #000;
}

.commit-icon span:first-child{
    background-color:#63993D;
    margin-top: 7px;

}

.commit-icon span:last-child{
    background-color: #F0561D;
}

.smh-avatar{
    width: 30px;
    display: inline-block;
    background: lightgrey;
    padding: 5px;
    border-radius: 50%;
    margin-right: 10px;
    height: 30px;
    text-align: center;
    vertical-align: middle;
    text-transform: uppercase;
}

.smh-avatar:hover{
    background-color: #006cd7;
    color: #fff;
}

.bottom-space{
    padding-bottom: 20px;
}