/*
 * Licensed to the Apache Software Foundation (ASF) under one or more
 * contributor license agreements.  See the NOTICE file distributed with
 * this work for additional information regarding copyright ownership.
 * The ASF licenses this file to You under the Apache License, Version 2.0
 * (the "License"); you may not use this file except in compliance with
 * the License.  You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

.dsl-modal {
    height: 80%;
    color: var(--pf-v5-global--Color--100);
}

.dsl-modal .dsl-card {
    cursor: pointer;
}

.dsl-modal .dsl-card .pf-v5-c-card__header-main {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    overflow-x: hidden;
}

.dsl-modal .dsl-card .header-labels .pf-v5-c-card__header-main {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.dsl-modal .dsl-card .icon {
    height: 20px;
    width: auto;
    border: none;
    margin-right: 6px;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -o-user-select: none;
    user-select: none;
}

.dsl-modal .search {
    position: absolute;
    top: 24px;
    right: 72px;
    display:flex;
    justify-content:flex-end;
}

.dsl-modal .search .pf-v5-c-form__group-label {
    padding: 0;
    margin: auto;
}

.dsl-modal .search .text-field {
    height: auto;
}

.dsl-modal .pf-v5-c-form.pf-v5-m-horizontal .pf-v5-c-form__group {
    display: contents;
}

.dsl-modal .pf-v5-c-card__body {
    padding-bottom: 0;
    height: 54px;
}


.dsl-modal .dsl-card p {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    color: var(--pf-v5-global--Color--200);
}
.dsl-modal .pf-v5-c-card__footer {
    padding-bottom: 1em;
}

.dsl-modal .footer {
    display: flex;
    flex-direction: row;
    margin-top: 6px;
    flex-wrap: wrap;
}

.dsl-modal .dsl-card .dsl-card-title {
    color: var(--pf-v5-global--Color--100);
    font-weight: bold;
}

.dsl-modal .dsl-card .header-labels {
    padding: 5px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.dsl-modal .dsl-card .footer-labels {
    padding: 5px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.dsl-modal .version,
.dsl-modal .support-type,
.dsl-modal .support-level {
    white-space: nowrap;
}

.dsl-modal .labels {
    opacity: 0.5;
    font-weight: 200;
    text-wrap: none;
}

.dsl-modal .dsl-card:hover .pf-v5-c-badge {
    margin-right: 1px;
}

.dsl-modal .dsl-card:hover {
    background-color: var(--pf-v5-global--palette--black-200);
}

.dsl-modal .dsl-card:hover .pf-v5-c-badge.pf-m-read {
    --pf-v5-c-badge--BackgroundColor: var(--pf-v5-global--palette--black-150);
}

.dsl-modal .dsl-card:hover p {
    /*color: var(--pf-v5-global--Color--200);*/
}

.dsl-modal .dsl-fast-card .header p {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.dsl-modal .dsl-fast-card .header {
    padding: 10px;
}

.dsl-modal .dsl-fast-card .fast-delete {
    padding: 0;
    margin: 0;
    width: 16px;
    height: 16px;
    vertical-align: text-bottom;
    position: absolute;
    right: 0;
    top: -1px;
    visibility: hidden;
}

.dsl-modal .dsl-fast-card .fast-delete .pf-v5-svg {
    fill: var(--pf-v5-global--danger-color--100);
    width: 16px;
    height: 16px;
}

.dsl-modal .dsl-fast-card:hover .fast-delete {
    visibility: visible;
}