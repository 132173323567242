.percante-elements {
    min-width: 116px;
    display: inline-block;
    height: 15px;
    margin-left: 7px;
}

.percante-elements .delete-icons {
    background: #F44336;
    display: inline-block;
    width: 50%;
    height: 15px;
}

.percante-elements .insert-icons {
    background: #4DAE51;
    display: inline-block;
    width: 50%;
    height: 15px;
}

.count-span {
    height: 15px;
    font-size: large;
}

.table-elements {
    margin-left: 8px;
    font-size: 20px;
    color: grey;
}

.user-profile-username {
    color: #0066CC;
    cursor: default;
    font-size: 15px;
    margin-left: 7px;
    margin-right: 10px;
}

.col-negative-cls {
  background-color: #FAE9E8;
}

.col-positive-cls {
    background-color: #F3FAF2;
  }

  .commit-user-info .custom-username {
    color: #70AAE2;
  }

  .commit-table-list {

  }
  
  div .pf-m-flex-2:has(.commit-table-list) {
    width: 90% !important;
  }

  .commit-long-text::first-letter {
    text-transform: uppercase;
  }