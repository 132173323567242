/*
 * Licensed to the Apache Software Foundation (ASF) under one or more
 * contributor license agreements.  See the NOTICE file distributed with
 * this work for additional information regarding copyright ownership.
 * The ASF licenses this file to You under the Apache License, Version 2.0
 * (the "License"); you may not use this file except in compliance with
 * the License.  You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

.karavan .topology-panel .pf-v5-c-toolbar {
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    height: fit-content;
    row-gap: 0;
}

.karavan .topology-panel .pf-v5-c-toolbar .pf-v5-c-toolbar__content {
    padding: 0 6px 0 0;
}

.karavan .topology-panel .pf-v5-c-toolbar .pf-v5-c-toolbar__content-section {
    margin: 6px 0 6px 0;
}

.karavan .topology-panel .pf-v5-c-toolbar .pf-v5-c-toolbar__group{
    width: 100%
}

.karavan .topology-panel .properties {
    padding: 16px;
    overflow: auto;
}

.karavan .topology-panel .properties .headers {
    display: none;
}

.karavan .topology-panel .common-node .icon {
    height: 32px;
    width: 32px;
}

.karavan .topology-panel .pf-v5-c-panel__header {
    padding-bottom: 0;
}

.karavan .topology-panel .file-button {
    padding: 0;
}

.karavan .topology-sidebar .pf-topology-side-bar__header {
    margin-right: 0;
}

.karavan .topology-toolbar {
    padding: 0;
}

.karavan .topology-sidebar {
}

.karavan .topology-sidebar .pf-topology-side-bar__dismiss {
    display: none;
}

.karavan .pf-topology-side-bar > .pf-topology-side-bar__header + * {
    margin-top: 0;
}

.karavan .topology-sidebar .properties .pf-v5-c-form {
    pointer-events: none;
    opacity: 0.7;
}
.karavan .topology-sidebar .properties .pf-v5-c-form .pf-v5-c-form__group-label-help {
    pointer-events: auto;
    opacity: 1;
}