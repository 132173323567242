.configuration-menu-items div{
    background-color: #05012C;
}
.configuration-menu-item-element {
    background-color: #05012C;
}
.configuration-menu-item-element:hover{
    background-color: #05012C;
}
.configuration-menu-open-toggle .pf-v5-c-menu .pf-v5-c-menu__list {
    background-color: white;
}
.configuration-menu-open-toggle .pf-v5-c-menu .pf-v5-c-menu__list button {
    justify-content: center;
}
.configuration-menu-open-toggle .pf-v5-c-menu .pf-v5-c-menu__list li:hover {
    background-color: #f0f0f0 !important;
}
.config-open-menu-list li {
    height: 50px;
}
.config-open-menu-list li:hover{
    border: 1px solid #F0F0F0;
    background-color: grey;
    justify-content: center;
}
.iframe-configuration-menu iframe{
    min-height: 500 !important;
    max-height: 900 !important;
    height: 600 !important;
}
.dashboard-title-custom {
    font-size: 24px;
    color: #05012C;
    font-weight: bold;
}
.nav-button-selected {
    background: grey !important;
}
.sub-nav-button-selected {
    background: #D3D3D3 !important;
}